import { gql } from "@apollo/client";

//////////
// User //
//////////
export const CREATE_USER = gql`
    mutation createUser(
        $email: String!
        $displayName: String!
        $userId: String!
    ) {
        createUser(email: $email, displayName: $displayName, userId: $userId) {
            id
            displayName
            email
            useDarkMode
            organization {
                id
                name
            }
            groupList
            scopes
            analyticsFollowList
        }
    }
`;

export const CREATE_USER_WITH_INVITATION = gql`
    mutation createUserWithInvitation(
        $email: String!
        $displayName: String!
        $userId: String!
    ) {
        createUserWithInvitation(
            email: $email
            displayName: $displayName
            userId: $userId
        ) {
            id
            displayName
            email
            useDarkMode
            organization {
                id
                name
            }
            groupList
            scopes
            analyticsFollowList
        }
    }
`;

export const UPDATE_ME = gql`
    mutation updateMe(
        $useDarkMode: Boolean
        $analyticsMode: Boolean
        $testerMode: Boolean
        $followListId: String
        $organizationId: ID
    ) {
        updateMe(
            useDarkMode: $useDarkMode
            analyticsMode: $analyticsMode
            testerMode: $testerMode
            followListId: $followListId
            organizationId: $organizationId
        ) {
            id
            displayName
            email
            useDarkMode
            analyticsMode
            testerMode
            organization {
                id
                name
            }
            groupList
            scopes
            analyticsFollowList
        }
    }
`;

export const UPDATE_USER = gql`
    mutation updateUser(
        $userId: ID!
        $scopes: [String]
        $followList: [String]
        $productId: ID
        $projectId: ID
        $organizationId: ID
        $handleProjects: Boolean
    ) {
        updateUser(
            userId: $userId
            scopes: $scopes
            followList: $followList
            productId: $productId
            projectId: $projectId
            organizationId: $organizationId
            handleProjects: $handleProjects
        ) {
            id
            displayName
            email
            useDarkMode
            organization {
                id
                name
            }
            groupList
            scopes
            analyticsFollowList
        }
    }
`;

export const UPDATE_USER_ACCESS_BATCH = gql`
    mutation updateUserAccessBatch($userId: ID!, $organizationId: ID!) {
        updateUserAccessBatch(
            userId: $userId
            organizationId: $organizationId
        ) {
            id
            displayName
            email
            useDarkMode
            organization {
                id
                name
            }
            groupList
            scopes
            analyticsFollowList
        }
    }
`;

export const REMOVE_USER = gql`
    mutation removeUser($userId: ID!) {
        removeUser(userId: $userId)
    }
`;

//////////////////
// Organization //
//////////////////
export const CREATE_ORGANIZATION = gql`
    mutation createOrganization($name: String!) {
        createOrganization(name: $name) {
            id
            name
            organizationType
        }
    }
`;

export const UPDATE_ORGANIZATION = gql`
    mutation updateOrganization(
        $organizationId: ID!
        $name: String
        $type: Int
        $organizationIdentifier: String
    ) {
        updateOrganization(
            organizationId: $organizationId
            name: $name
            type: $type
            organizationIdentifier: $organizationIdentifier
        ) {
            id
            name
            organizationType
            organizationIdentifier
        }
    }
`;

export const ADD_USER_TO_ORGANIZATION = gql`
    mutation addUserToOrg($email: String!) {
        addUserToOrganization(email: $email)
    }
`;

/////////////////////////
// Organization Config //
/////////////////////////
export const UPDATE_ORGANIZATION_CONFIG = gql`
    mutation updateOrganizationConfig($input: OrganizationConfigInput!) {
        updateOrganizationConfig(input: $input) {
            id
            softLimits
        }
    }
`;

/////////////
// Product //
/////////////
export const CREATE_PRODUCT = gql`
    mutation createProduct($name: String!, $organizationId: Int!) {
        createProduct(name: $name, organizationId: $organizationId) {
            id
            name
            userImpressions
            userGameplay
            userEndScreen
            impressions
            gameplay
            endScreen
            dateUpdated
            organization {
                id
                name
                dateUpdated
            }
        }
    }
`;

export const REMOVE_PRODUCT = gql`
    mutation removeProduct($productId: ID!) {
        removeProduct(productId: $productId)
    }
`;

export const UPDATE_PRODUCT = gql`
    mutation updateProduct($productId: ID!, $name: String) {
        updateProduct(productId: $productId, name: $name) {
            id
            name
        }
    }
`;

/////////////
// Project //
/////////////
export const CREATE_PROJECT = gql`
    mutation createProject(
        $name: String!
        $productId: ID!
        $gitProjectId: Int
        $gitProjectName: String
        $gitProjectBranch: String
        $slackChannel: String
    ) {
        createProject(
            name: $name
            productId: $productId
            gitProjectId: $gitProjectId
            gitProjectName: $gitProjectName
            gitProjectBranch: $gitProjectBranch
            slackChannel: $slackChannel
        ) {
            id
            name
            state
            gitProjectId
            gitProjectName
            gitProjectBranch
            slackChannel
            userImpressions
            userGameplay
            userEndScreen
            impressions
            gameplay
            endScreen
            dateUpdated
            product {
                id
                name
            }
        }
    }
`;

export const CREATE_DEVELOPER_PROJECT = gql`
    mutation createDeveloperProject($config: String!) {
        createDeveloperProject(config: $config) {
            project {
                id
                name
                gitProjectId
                gitProjectName
                gitProjectBranch
                slackChannel
                state
                dateUpdated
                product {
                    id
                    name
                }
            }
            taskId
        }
    }
`;

export const UPDATE_PROJECT = gql`
    mutation updateProject(
        $projectId: ID!
        $name: String
        $gitProjectId: Int
        $gitProjectName: String
        $gitProjectBranch: String
        $slackChannel: String
    ) {
        updateProject(
            projectId: $projectId
            name: $name
            gitProjectId: $gitProjectId
            gitProjectName: $gitProjectName
            gitProjectBranch: $gitProjectBranch
            slackChannel: $slackChannel
        ) {
            id
            name
            state
            gitProjectId
            gitProjectName
            gitProjectBranch
            slackChannel
            userImpressions
            userGameplay
            userEndScreen
            impressions
            gameplay
            endScreen
            dateUpdated
            product {
                id
                name
            }
        }
    }
`;

export const REMOVE_PROJECT = gql`
    mutation removeProject($projectId: ID!) {
        removeProject(projectId: $projectId)
    }
`;

/////////////////////////
// Project Config //
/////////////////////////
export const UPDATE_PROJECT_CONFIG = gql`
    mutation updateProjectConfig($input: ProjectConfigInput!) {
        updateProjectConfig(input: $input) {
            id
            softLimits
            analyticsNetworks
            zipNameTemplate
        }
    }
`;

export const UPDATE_NEW_PROJECT_CONFIG = gql`
    mutation adminUpdateNewProjectConfig(
        $configKey: String!
        $configValue: String!
    ) {
        adminUpdateNewProjectConfig(
            configKey: $configKey
            configValue: $configValue
        ) {
            gitModules
            baseProjects
            techStack
        }
    }
`;

//////////////
// Revision //
//////////////
export const CREATE_ADSET = gql`
    mutation createAdset($projectId: ID!, $revisionId: ID!) {
        createAdset(projectId: $projectId, revisionId: $revisionId) {
            id
            project {
                id
                name
            }
            name
            branch
            gitId
            buildData
            dateCreated
        }
    }
`;

export const COPY_REVISION = gql`
    mutation copyRevision($projectId: ID!, $revisionId: ID!) {
        copyRevision(projectId: $projectId, revisionId: $revisionId) {
            id
            name
            branch
            gitId
            releaseType
            buildData
            analyticsConfig
            dateCreated
            dateUpdated
            archived
            userImpressions
            userGameplay
            userEndScreen
            impressions
            gameplay
            endScreen
            project {
                id
                name
                product {
                    id
                    name
                }
            }
        }
    }
`;

export const REMOVE_REVISION = gql`
    mutation removeRevision($revisionId: ID!) {
        removeRevision(revisionId: $revisionId)
    }
`;

export const UPDATE_REVISION = gql`
    mutation updateRevision(
        $revisionId: ID!
        $projectId: ID
        $name: String
        $branch: String
        $gitId: Int
        $analyticsConfig: String
        $releaseType: Int
        $archived: Boolean
    ) {
        updateRevision(
            revisionId: $revisionId
            projectId: $projectId
            name: $name
            branch: $branch
            gitId: $gitId
            analyticsConfig: $analyticsConfig
            releaseType: $releaseType
            archived: $archived
        ) {
            id
            project {
                id
                name
            }
            name
            branch
            gitId
            releaseType
            buildData
            analyticsConfig
            archived
            dateCreated
        }
    }
`;

export const PARTIAL_UPDATE_REVISION = gql`
    mutation partialUpdateRevision(
        $revisionId: ID!
        $partialData: PartialRevisionInput!
        $operation: String
    ) {
        partialUpdateRevision(
            revisionId: $revisionId
            partialData: $partialData
            operation: $operation
        ) {
            version
            newId
            operation
        }
    }
`;

export const ATTACH_REVISION = gql`
    mutation updateRevision($id: ID!, $project: ID) {
        updateRevision(id: $id, project: $project) {
            id
            project {
                id
                name
                product {
                    id
                    name
                }
            }
            name
            branch
            gitId
            releaseType
            buildData
            archived
            dateCreated
            dateUpdated
        }
    }
`;

/////////////////////
// Revision Config //
/////////////////////
export const UPDATE_REVISION_CONFIG = gql`
    mutation updateRevisionConfig($input: RevisionConfigInput!) {
        updateRevisionConfig(input: $input) {
            id
            configurator
            templatorConfig
            setBlacklist
            useTemplatorInPipeline
            allowTemplatorConfigModification
        }
    }
`;

export const UPDATE_REVISION_CONFIG_CONFIGURATOR = gql`
    mutation updateRevisionConfig($input: RevisionConfigInput!) {
        updateRevisionConfig(input: $input) {
            id
            configurator
            templatorConfig
            sets
            setBlacklist
        }
    }
`;

////////////////
// Variations //
////////////////
export const UPLOAD_NEW_LOCALIZATION = gql`
    mutation addLocalization(
        $revisionId: ID!
        $localizationFileName: String!
        $localizationJson: String!
    ) {
        addLocalization(
            revisionId: $revisionId
            localizationFileName: $localizationFileName
            localizationJson: $localizationJson
        ) {
            version
            partialData
            revisionConfig {
                id
                localizations
            }
        }
    }
`;

export const UPLOAD_LOCALIZATION = gql`
    mutation uploadLocalization(
        $revisionId: ID!
        $localization: String!
        $localizationJson: String!
    ) {
        uploadLocalization(
            revisionId: $revisionId
            localization: $localization
            localizationJson: $localizationJson
        ) {
            revisionConfig {
                id
                localizations
            }
        }
    }
`;

export const REMOVE_LOCALIZATION = gql`
    mutation removeLocalization($revisionId: ID!, $localization: String!) {
        removeLocalization(
            revisionId: $revisionId
            localization: $localization
        ) {
            id
            localizations
        }
    }
`;

//////////
// Build //
//////////
export const CREATE_BUILD = gql`
    mutation createBuild(
        $revisionId: ID!
        $buildData: String
        $buildType: Int
    ) {
        createBuild(
            revisionId: $revisionId
            buildData: $buildData
            buildType: $buildType
        ) {
            id
            state
            reportData
            dateCreated
            dateUpdated
        }
    }
`;

export const UPDATE_BUILD = gql`
    mutation updateBuild($id: ID!, $state: Int!) {
        updateBuild(id: $id, state: $state) {
            id
            state
            reportData
            dateCreated
            dateUpdated
        }
    }
`;

export const CANCEL_BUILD = gql`
    mutation cancelBuild($buildId: ID!) {
        cancelBuild(buildId: $buildId) {
            id
            state
            reportData
            dateCreated
            dateUpdated
        }
    }
`;

/////////////////////
// Revision Events //
/////////////////////
export const CREATE_REVISION_EVENT = gql`
    mutation createEvent(
        $revisionId: ID!
        $eventType: Int!
        $description: String
    ) {
        createEvent(
            revisionId: $revisionId
            eventType: $eventType
            description: $description
        ) {
            id
            eventType
            description
        }
    }
`;

///////////////////
// Notifications //
///////////////////
export const UPDATE_NOTIFICATION = gql`
    mutation updateNotification($notificationId: ID!, $isNew: Boolean!) {
        updateNotification(notificationId: $notificationId, isNew: $isNew) {
            id
            title
            message
            dateCreated
            dateUpdated
            isNew
        }
    }
`;

export const REMOVE_NOTIFICATION = gql`
    mutation removeNotification($notificationId: ID!) {
        removeNotification(notificationId: $notificationId)
    }
`;

///////////////////
// Network Tests //
///////////////////
export const CREATE_NETWORK_TEST = gql`
    mutation createNetworkTest($networkBuildId: ID!) {
        createNetworkTest(networkBuildId: $networkBuildId) {
            id
            networkBuild {
                id
            }
            state
            network
            report
            testJobId
            dateCreated
            dateUpdated
        }
    }
`;

export const CANCEL_NETWORK_TEST = gql`
    mutation cancelNetworkTest($networkTestId: ID!) {
        cancelNetworkTest(networkTestId: $networkTestId) {
            id
            state
        }
    }
`;

///////////////
// Analytics //
///////////////
export const GENERATE_ANALYTICS_TASK = gql`
    mutation generateAnalyticsTask($input: AnalyticsQueryInput!) {
        generateAnalyticsTask(input: $input) {
            taskId
            subTasks {
                subTaskCount
                completedCount
            }
        }
    }
`;

export const UPDATE_ANALYTICS_SCHEMA = gql`
    mutation updateAnalyticsSchema($buildId: ID!, $schema: String!) {
        updateAnalyticsSchema(buildId: $buildId, schema: $schema) {
            success
            schema
        }
    }
`;

export const REMOVE_ANALYTICS_QUERY = gql`
    mutation removeAnalyticsQuery($id: ID!) {
        removeAnalyticsQuery(id: $id)
    }
`;

export const ADD_ANALYTICS_QUERY = gql`
    mutation addAnalyticsQuery(
        $name: String!
        $description: String
        $type: Int!
        $config: String!
        $queryTaskId: Int
    ) {
        createAnalyticsQuery(
            name: $name
            description: $description
            type: $type
            config: $config
            queryTaskId: $queryTaskId
        ) {
            id
            name
            description
            type
            config
            queryTask {
                id
            }
        }
    }
`;

export const UPDATE_ANALYTICS_LIB_EVENT = gql`
    mutation updateAnalyticsLibEvent(
        $eventName: String!
        $eventData: String
        $comment: String
    ) {
        updateAnalyticsLibEvent(
            eventName: $eventName
            eventData: $eventData
            comment: $comment
        ) {
            id
            eventName
            eventData
            comment
            frozen
        }
    }
`;

export const ADMIN_UPDATE_ANALYTICS_LIB_EVENT = gql`
    mutation adminUpdateAnalyticsLibEventFrozen(
        $eventId: ID!
        $frozen: Boolean!
    ) {
        adminUpdateAnalyticsLibEventFrozen(eventId: $eventId, frozen: $frozen) {
            id
            frozen
        }
    }
`;

export const ADMIN_DELETE_ANALYTICS_LIB_EVENT = gql`
    mutation adminDeleteAnalyticsLibEvent($eventId: ID!) {
        adminDeleteAnalyticsLibEvent(eventId: $eventId)
    }
`;

//////////////////////
// Asset Management //
//////////////////////
export const REMOVE_FILE_UPLOADS = gql`
    mutation removeAsset($id: ID!) {
        removeAsset(id: $id)
    }
`;

//////////
// Link //
//////////
export const GENERATE_LINK_PAGE = gql`
    mutation generateLinkPage($id: ID!) {
        generateLinkPage(id: $id) {
            success
            message
        }
    }
`;

export const GET_DOWNLOAD_ZIP = gql`
    mutation generateDownloadLink($id: ID!) {
        generateDownloadLink(id: $id) {
            link
        }
    }
`;

export const AUTHENTICATE_CLI = gql`
    mutation generateDevApiKey($removeOld: Boolean!) {
        generateDevApiKey(removeOld: $removeOld) {
            key
            result
        }
    }
`;

////////////
// Aditor //
////////////
export const ADITOR_CREATE_SCENE = gql`
    mutation aditorCreateScene($input: AditorCreateInput!) {
        aditorCreateScene(input: $input) {
            id
            data
        }
    }
`;

export const ADITOR_DELETE_SCENE = gql`
    mutation aditorDeleteScene($revisionId: ID!) {
        aditorDeleteScene(revisionId: $revisionId)
    }
`;

export const ADITOR_UPDATE_SCENE = gql`
    mutation aditorUpdateScene($input: AditorUpdateInput!) {
        aditorUpdateScene(input: $input)
        updateAllLocalizations(input: $input) {
            version
            partialData
        }
    }
`;

export const ADITOR_PLAY_SCENE = gql`
    mutation aditorPlayScene($revisionId: ID!) {
        aditorPlayScene(revisionId: $revisionId) {
            success
            taskId
            message
        }
    }
`;

export const ADITOR_TEMPLATE_UPLOAD = gql`
    mutation aditorTemplateUpload($templateKey: String!, $data: String!) {
        aditorTemplateUpload(templateKey: $templateKey, data: $data)
    }
`;

export const ADITOR_TEMPLATE_REMOVE = gql`
    mutation aditorTemplateRemove($templateKey: String!) {
        aditorTemplateRemove(templateKey: $templateKey)
    }
`;

/////////////
// Mutator //
/////////////
export const MUTATOR_CREATE_CONFIG = gql`
    mutation mutatorCreateConfig($revisionId: ID!) {
        mutatorCreateConfig(revisionId: $revisionId) {
            mutatorConfig
            mutatorKeyPrefix
        }
    }
`;

export const MUTATOR_DELETE_CONFIG = gql`
    mutation mutatorRemoveConfig($revisionId: ID!) {
        mutatorRemoveConfig(revisionId: $revisionId)
    }
`;

export const MUTATOR_UPDATE_CONFIG = gql`
    mutation mutatorUpdateConfig($input: MutatorUpdateInput!) {
        mutatorUpdateConfig(input: $input) {
            mutatorConfig
            mutatorKeyPrefix
        }
    }
`;

////////////////
// Invitation //
////////////////
export const CREATE_USER_INVITATION = gql`
    mutation userInvitation($email: String!, $organizationId: ID!) {
        userInvitation(email: $email, organizationId: $organizationId) {
            email
            token
            url
            dateExpired
        }
    }
`;

/////////////////////
// Platform Config //
/////////////////////
export const UPDATE_PLATFORM_CONFIG = gql`
    mutation updatePlatformConfig($input: PlatformConfigInput!) {
        updatePlatformConfig(input: $input) {
            id
            useBuildRunner
            supportedNetworks
            buildRunnerNetworks
            analyticsNetworks
            qrNetworks
            networkTestingNetworks
            networkTestingMinimumState
            analyticsApiVersion
        }
    }
`;
